<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Work Orders</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>

            <v-tabs
                v-model="tab"
                fixed-tabs
            >
                <v-tab key="1">
                    Details
                </v-tab>
                <v-tab key="2">
                    History
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">

                <!-- DETAILS -->
                <v-tab-item key="1">
                    <br />

                    <v-row>
                        <v-col
                            cols="12"
                            lg="9"
                            md="12"
                            sm="12"
                        >
                            <label>Project</label>
                            <v-combobox
                                v-model="workOrderRequest.projectSelected"
                                :items="listProjects"
                                item-text="description"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                single-line
                                outlined
                                dense
                                @change="getListProjectItems(true)"
                            ></v-combobox>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Construction Phase</label>
                            <v-combobox
                                v-model="workOrderRequest.stageSelected"
                                :items="listConstructionStages" 
                                :rules=[validations.required]
                                item-text="stageDescription"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="12"
                            sm="12"
                        >
                            <label>Product Category</label>
                            <v-combobox
                                v-model="workOrderRequest.listProductCategorySelected"
                                :items="listProductCategory"
                                item-text="description"
                                item-value="id"
                                multiple
                                chips
                                :rules=[validations.required]
                                clearable
                                single-line
                                outlined
                                dense
                                @change="filterProductsByCategory(true)"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @mousedown.prevent
                                        @click="toggleProductCategory"
                                    >
                                        <v-list-item-action>
                                            <v-icon :color="listProductCategory.length > 0 ? 'indigo darken-4' : ''">
                                                {{ iconProductCategory }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-combobox>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="6"
                            md="12"
                            sm="12"
                        >
                            <label>Product</label>
                            <v-combobox
                                v-model="workOrderRequest.listProductSelected"
                                :items="listProjectItemsFiltered"
                                item-text="description"
                                item-value="idProduct"
                                multiple
                                chips
                                clearable
                                single-line
                                outlined
                                dense
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @mousedown.prevent
                                        @click="toggleProduct"
                                    >
                                        <v-list-item-action>
                                            <v-icon :color="listProjectItemsFiltered.length > 0 ? 'indigo darken-4' : ''">
                                                {{ iconProduct }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>                            
                            </v-combobox>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <v-menu
                                ref="mnuTargetDate"
                                v-model="mnuTargetDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label>Target Date</label>
                                    <v-text-field
                                        v-model="targetDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @blur="targetDate = parseDate(targetDateFormatted)"
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="targetDate"
                                    no-title
                                    @input="mnuTargetDate = false"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Type</label>
                            <v-combobox
                                v-model="workOrderRequest.workOrderTypeSelected"
                                :items="listWorkOrderType"
                                item-text="description"
                                item-value="value"
                                :rules=[validations.required]
                                clearable
                                single-line
                                outlined
                                dense
                                @change="workOrderRequest.installerSelected = null;"
                            ></v-combobox>
                        </v-col>
                        <v-col 
                            v-if="showInternalInstaller"
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>In House</label>
                            <v-combobox
                                v-model="workOrderRequest.installerSelected"
                                :items="listUser"
                                item-text="name"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                single-line
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                        <v-col 
                            v-if="showExternalInstaller"
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Subcontractor</label>
                            <v-combobox
                                v-model="workOrderRequest.installerSelected"
                                :items="listContractor"
                                item-text="name"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                single-line
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                        <v-col 
                            v-if="showExternalInstaller"
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Crew</label>
                            <v-text-field
                                v-model="workOrderRequest.installerName" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Field Super</label>
                            <v-combobox
                                v-model="workOrderRequest.fieldSuperSelected"
                                :items="listUserSupervisor"
                                item-text="name"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                single-line
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Tile</label>
                            <v-text-field
                                v-model="workOrderRequest.tile" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Tile Grout</label>
                            <v-text-field
                                v-model="workOrderRequest.tileGrout" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Coping</label>
                            <v-text-field
                                v-model="workOrderRequest.coping" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Coping Grout</label>
                            <v-text-field
                                v-model="workOrderRequest.copingGrout" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>

                        <v-col 
                            v-if="showMaterial"
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Material</label>
                            <v-text-field
                                v-model="workOrderRequest.material" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            v-if="showRedTagComments"
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Red Tag Comments</label>
                            <v-text-field
                                v-model="workOrderRequest.redTagComments" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            v-if="showColor"
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Color</label>
                            <v-text-field
                                v-model="workOrderRequest.color" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Scope of Work</label>
                            <v-textarea
                                v-model="workOrderRequest.scopeOfWork"
                                single-line
                                rows="3"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>
                            <v-textarea
                                v-model="workOrderRequest.notes"
                                single-line
                                rows="3"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <canvas id="canvas" hidden></canvas>
                            <label>Pictures</label>
                            <v-file-input
                                v-model="workOrderDetailFilesPhotos" 
                                accept="image/*"
                                small-chips
                                multiple
                                outlined
                                placeholder="Click here to add"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                @change="resizePreviewImage(workOrderDetailFilesPhotos, workOrderDetailPhotos)"
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                            v-for="(itemPhoto, index) in workOrderDetailPhotos.filter(pht => pht.deleted == false)"
                            :key="index"
                            cols="12"
                            lg="1"
                            md="4"
                            sm="12"
                            style="margin-top: 10px;"
                        >
                            <v-card max-width="200">
                                <v-img
                                    :src="itemPhoto.src"
                                    :lazy-src="itemPhoto.src"
                                    aspect-ratio="1"
                                    max-height="200"
                                    max-width="200"
                                >
                                    <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                    </template>
                                </v-img>
                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(workOrderDetailPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-tab-item>

                <!-- HISTORY -->
                <v-tab-item key="2">
                    <br />

                    <v-row>
                        <v-col
                            style="text-align: right"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2"
                                        v-on="on"
                                        @click="showDialogNoteAndClearFields()"
                                    >
                                        Add New Note
                                    </v-btn>
                                </template>
                                <span>Add New Note</span>
                            </v-tooltip>
                        </v-col>
                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <br />
                            <v-data-table
                                :headers="headersNote"
                                :items="listHistory"
                                no-data-text="No notes registered"
                                hide-default-footer
                            >
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :id="item.idWorkOrderReference" 
                                        :showButtons="{
                                            edit: true,
                                            delete: true
                                        }"
                                        @customActionEdit="showDialogNoteAndFillFields"
                                        @confirmDelete="confirmNoteDelete" />
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-dialog
                        v-model="showDialogNote"
                        transition="dialog-top-transition"
                        persistent
                        width="1000"
                        :fullscreen="$vuetify.breakpoint.mobile"
                    >
                    
                        <v-card>
                            <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                                Note
                            </v-card-title>

                            <v-card-text style="margin-top: 20px;">
                                <v-form
                                    ref="formNote"
                                    v-model="validFormNote"
                                    lazy-validation
                                >
                                    <v-row v-if="itemNote == null || itemNote == undefined || userLoggedGetters.profile.systemAdmin == 1">
                                        <v-col 
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-switch 
                                                v-model="visibility"
                                                label="Visible by Everyone ?"
                                                :value=2
                                                color="var(--color__main)"
                                            >
                                            </v-switch>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <label>New Note</label>
                                            
                                            <v-textarea
                                                v-model="note"
                                                :rules=[validations.required]
                                                single-line
                                                rows="3"
                                                row-height="30"
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <label>Pictures</label>
                                            <v-file-input
                                                v-model="workOrderFilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(workOrderFilesPhotos, workOrderPhotos)"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in workOrderPhotos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card max-width="200">
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="200"
                                                    max-width="200"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(workOrderPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                </v-form>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <div style="width: 100%; text-align: center;">
                                    <v-btn
                                        class="mx-2 buttonCancelDialog"
                                        style=""
                                        outlined
                                        @click="showDialogNote = false"
                                    >
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                        class="mx-2"
                                        outlined
                                        @click="saveNote()"
                                    >
                                        Save
                                    </v-btn>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>


                </v-tab-item>

            </v-tabs-items>

            <br />

            <ActionButtons
                :request="workOrderRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />

            <ActionDialog 
                v-on:methodConfirmToCall="dialog.methodConfirm"
                :showDialog.sync="dialog.show"
                :headerTitle="dialog.headerText"
                :bodyText="dialog.bodyText"
                :params="dialog.params"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT } from "@/utilities/Enums";
	import * as moment from 'moment';

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ActionList,
            ActionDialog
        },

        data: vm => ({

            tab: null,

            validForm: true,
            validFormNote: true,

            workOrderRequest: {
                id: 0,
                projectSelected: null,
                stageSelected: null,
                idProduct: 0,
                listProductSelected: null,
                listProductCategorySelected: null,
                workOrderTypeSelected: { id: 1, description: 'Internal' },
                installerSelected: null,
                idInstaller: 0,
                scopeOfWork: "",
                notes: "",
                fieldSuperSelected: null,
                idFieldSuper: 0,
            },
            listHistory: [],

            targetDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            targetDateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            mnuTargetDate: false,

            warrantyExpirationDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            warrantyExpirationDateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            mnuWarrantyExpirationDate: false,

            listProjects: [],
            listProjectItems: [],
            listProjectItemsFiltered: [],
            listProductCategory: [],
            listWorkOrderType: [],
            listUser: [],
            listUserSupervisor: [],
            listContractor: [],
            listConstructionStages: [],

            //NOTE
            showDialogNote: false,
            note: "",
            visibility: 0,
            itemNote: null,

            headersNote: [
                { text: "Date", value: "dateRegisterFormatted", sortable: true },
                { text: "Username", value: "userName", sortable: true },
                { text: "Note", value: "description", sortable: true },
                { text: "Visibility", value: "visibilityDescription", sortable: true },
                { text: "Qtd Pictures", value: "qtdPictures", sortable: false, align: "center" },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            workOrderFilesPhotos: [],
            workOrderPhotos: [],

            workOrderDetailFilesPhotos: [],
            workOrderDetailPhotos: [],

            listPhotosDelete: [],
            itemPhotoDelete: {},

            loading: false,

            validations: {
                required: required,
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
        }),

        props: {
            id: {
                default: 0
            },

            idPanel: {
                default: 0
            },

            idServiceProject: {
                default: 0
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState', menuGetter: 'menuState' }),

            showInternalInstaller() {
                return this.workOrderRequest.workOrderTypeSelected.id == 1
            },

            showExternalInstaller() {
                return this.workOrderRequest.workOrderTypeSelected.id == 2
            },

            showBrickAndTile() {
                return this.workOrderRequest.listProductCategorySelected != null && 
                       this.workOrderRequest.listProductCategorySelected.filter(item => item.description.toString().toLowerCase().includes("brick")).length > 0;
            },
                        
            showMaterial() {
                return this.workOrderRequest.listProductCategorySelected != null && 
                       this.workOrderRequest.listProductCategorySelected.filter(item => item.description.toString().toLowerCase().includes("deck")).length > 0;
            },

            showRedTagComments() {
                return this.workOrderRequest.listProductCategorySelected != null && 
                       (this.workOrderRequest.listProductCategorySelected.filter(item => item.description.toString().toLowerCase().includes("eletrical")).length > 0 ||
                        this.workOrderRequest.listProductCategorySelected.filter(item => item.description.toString().toLowerCase().includes("bbq")).length > 0 || 
                        this.workOrderRequest.listProductCategorySelected.filter(item => item.description.toString().toLowerCase().includes("pergola")).length > 0);
            },

            showColor() {
                return this.workOrderRequest.listProductCategorySelected != null && 
                       this.workOrderRequest.listProductCategorySelected.filter(item => item.description.toString().toLowerCase().includes("plaster")).length > 0;
            },

            statusProjectDescription() {
                let statusDescription = '';

                if (this.workOrderRequest.projectSelected != null && this.workOrderRequest.projectSelected != undefined) {
                    statusDescription = this.workOrderRequest.projectSelected.statusDescription
                }

                return statusDescription;
            },

            allProductCategories () {
                return this.workOrderRequest.listProductCategorySelected != null && 
                       this.workOrderRequest.listProductCategorySelected != undefined &&
                       this.workOrderRequest.listProductCategorySelected.length === this.listProductCategory.length
            },

            someProductCategories () {
                return this.workOrderRequest.listProductCategorySelected != null && 
                       this.workOrderRequest.listProductCategorySelected != undefined &&
                       this.workOrderRequest.listProductCategorySelected.length > 0 && !this.allProductCategories
            },

            iconProductCategory () {
                if (this.allProductCategories) return 'mdi-close-box'
                if (this.someProductCategories) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },

            allProduct () {
                return this.workOrderRequest.listProductSelected != null && 
                       this.workOrderRequest.listProductSelected != undefined &&
                       this.workOrderRequest.listProductSelected.length === this.listProjectItemsFiltered.length
            },

            someProduct () {
                return this.workOrderRequest.listProductSelected != null && 
                       this.workOrderRequest.listProductSelected != undefined &&
                       this.workOrderRequest.listProductSelected.length > 0 && !this.allProduct
            },

            iconProduct () {
                if (this.allProduct) return 'mdi-close-box'
                if (this.someProduct) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
        },

        watch: {
            targetDate () {
                this.targetDateFormatted = this.formatDate(this.targetDate)
            },

            warrantyExpirationDate () {
                this.warrantyExpirationDateFormatted = this.formatDate(this.warrantyExpirationDate)
            },
        },

        methods: {

            toggleProductCategory () {
                this.$nextTick(() => {
                    if (this.allProductCategories) {
                        this.workOrderRequest.listProductCategorySelected = []
                    } else {
                        this.workOrderRequest.listProductCategorySelected = this.listProductCategory.slice()
                    }
                })
            },

            toggleProduct () {
                this.$nextTick(() => {
                    if (this.allProduct) {
                        this.workOrderRequest.listProductSelected = []
                    } else {
                        this.workOrderRequest.listProductSelected = this.listProjectItemsFiltered.slice()
                    }
                })
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("workOrderModule/GetById", this.id);

                    this.workOrderRequest = response.result;
                    this.listHistory = this.workOrderRequest.listHistory;

                    this.targetDate = this.parseDate(this.workOrderRequest.targetDateFormatted);
                    this.targetDateFormatted = this.workOrderRequest.targetDateFormatted;

                    if (this.workOrderRequest.warrantyExpirationDateFormatted != null && 
                        this.workOrderRequest.warrantyExpirationDateFormatted != undefined && 
                        this.workOrderRequest.warrantyExpirationDateFormatted != '') {

                        this.warrantyExpirationDate = this.parseDate(this.workOrderRequest.warrantyExpirationDateFormatted);
                        this.warrantyExpirationDateFormatted = this.workOrderRequest.warrantyExpirationDateFormatted;
                    }
                                                
                    this.workOrderDetailFilesPhotos = [];
                    this.workOrderDetailPhotos = [];

                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.WORK_ORDER.value, idKey: this.id });
                    let listPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.WORK_ORDER_DETAIL.value);  
                    listPhotos.forEach(itemPhoto => {

                        this.workOrderDetailPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    await this.listHistory.forEach(async itemHistory => {

                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.WORK_ORDER.value, idKey: itemHistory.id });

                        itemHistory.workOrderPhotos = [];

                        let listPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.WORK_ORDER_HISTORY.value);  
                        await listPhotos.forEach(async itemPhoto => {

                            itemHistory.workOrderPhotos.push({
                                id: itemPhoto.id,
                                file: new File([], itemPhoto.fileName),
                                fileName: itemPhoto.fileNameShort,
                                deleted: false,
                                src: `data:image/jpg;base64,${itemPhoto.file}`
                            })

                        })

                        itemHistory.qtdPictures = itemHistory.workOrderPhotos.length;
                    });

                    await this.getListProjectItems(false);

                    if (this.workOrderRequest.idListProductCategory != null && this.workOrderRequest.idListProductCategory != undefined) {
                        var listProductCategory = this.workOrderRequest.idListProductCategory.toString().split(';');

                        if (listProductCategory != null && listProductCategory != undefined) {

                            this.workOrderRequest.listProductCategorySelected = [];

                            listProductCategory.forEach(itemCategory => {
                                
                                const categorySelected = this.listProductCategory.filter(item => item.id == itemCategory)[0];

                                if (categorySelected != null && categorySelected != undefined) {
                                    this.workOrderRequest.listProductCategorySelected.push(categorySelected);
                                }
                            })
                        }
                    }

                    await this.filterProductsByCategory(false);
                    
                    this.$forceUpdate();
                }
                else {

                    if (this.idPanel != null && this.idPanel != undefined && this.idPanel != 0) {

                        if (this.idServiceProject != null && this.idServiceProject != undefined && this.idServiceProject != 0) {
                            const projectSelected = this.listProjects.filter(prj => prj.id == this.idServiceProject)[0];

                            if (projectSelected != null && projectSelected != undefined) {
                                this.workOrderRequest.projectSelected = projectSelected;
                            }
                        }
                    }
                }
            },
            
            async confirmPhotoDelete(listPhotos, itemPhotoToDelete) {

                this.listPhotosDelete = listPhotos;
                this.itemPhotoToDelete = itemPhotoToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this image [${itemPhotoToDelete.fileName}], confirm your decision?`,
                    methodConfirm: this.deletePhoto,
                    params: 0
                };
            },

            async deletePhoto() {

                this.itemPhotoToDelete.deleted = true;
            },

            async getLists() {
                
                this.listWorkOrderType = await this.$store.dispatch("workOrderModule/ListType");
                this.listProjects = await this.$store.dispatch("serviceModule/ListProjectCompact", "-");
                this.listUser = await this.$store.dispatch("userModule/List");
                this.listUserSupervisor = this.listUser.filter(usr => usr.constructionSupervisor == 1);
                this.listContractor = await this.$store.dispatch("contractorModule/List");
                this.listConstructionStages = await this.$store.dispatch("serviceModule/ListConstructionStages");
            },

            async getListProjectItems(clearProduct) {

                var idServiceProject = 0;
                this.workOrderRequest.listProductCategorySelected = null;

                if (clearProduct == true) {
                    this.workOrderRequest.listProductSelected = null;
                }

                if (this.workOrderRequest.projectSelected != null && this.workOrderRequest.projectSelected != undefined) {
                    idServiceProject = this.workOrderRequest.projectSelected.id;

                    const constructionSupervisor = this.listUserSupervisor.filter(item => item.id == this.workOrderRequest.projectSelected.idUserSupervisor)[0];

                    if (constructionSupervisor != null && constructionSupervisor != undefined) {
                        this.workOrderRequest.fieldSuperSelected = constructionSupervisor;
                    }
                }

                this.listProjectItems = await this.$store.dispatch("serviceModule/ListItems", idServiceProject );
                this.listProjectItemsFiltered = [...this.listProjectItems];
                
                if (this.listProjectItems != null && this.listProjectItems != undefined)
                {
                    this.listProductCategory = [];

                    this.listProjectItems.map(item => {

                        const existsCategory = this.listProductCategory.filter(cat => cat.id == item.idProductCategory)[0];

                        if (existsCategory == null || existsCategory == undefined) {
                            this.listProductCategory.push({ 
                                id: item.idProductCategory, 
                                description: item.productCategoryDescription 
                            })
                        }
                    });
                }
            },

            async filterProductsByCategory(clearProduct) {

                if (clearProduct == true) {
                    this.workOrderRequest.listProductSelected = null;
                }

                if (this.workOrderRequest.listProductCategorySelected != null && this.workOrderRequest.listProductCategorySelected != undefined) {

                    this.listProjectItemsFiltered = [];
                    
                    this.workOrderRequest.listProductCategorySelected.forEach(itemCategory => {

                        const listProducts = this.listProjectItems.filter(item => item.idProductCategory == itemCategory.id);

                        if (listProducts != null && listProducts != undefined) {

                            listProducts.forEach(itemProduct => {
                                this.listProjectItemsFiltered.push(itemProduct);
                            })
                        }
                    });
                }
            },

            async cancel() {
                this.$router.push({ path: "/workOrder/workOrderList" });
            },

            //NOTE
            async clearFieldsNote() {

                this.note = "";
                this.visibility = 0;
                this.itemNote = null;
                this.workOrderFilesPhotos = [];
                this.workOrderPhotos = [];
            },

            async showDialogNoteAndClearFields()  {

                this.clearFieldsNote();
                this.showDialogNote = true;
            },

            async showDialogNoteAndFillFields(id) {
                this.clearFieldsNote();
                this.itemNote = this.listHistory.filter(not => not.idWorkOrderReference == id)[0];

                if (this.itemNote != null && this.itemNote != undefined) {
                    
                    this.note = this.itemNote.description;
                    this.visibility = this.itemNote.visibility;
                    this.workOrderPhotos = this.itemNote.workOrderPhotos;
                    this.showDialogNote = true;
                }
            },

            async saveNote() {

                await this.$refs.formNote.validate();

                if (this.validFormNote === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the notes form. Please review!");
                    return false;
                }
                else {
                    let visibility = 1;
                    let visibilityDescription = "Van Kirk";

                    if (this.visibility != null && this.visibility != undefined) {
                        visibility = this.visibility;
                    }

                    if (visibility == 2) {
                        visibilityDescription = "Everyone"
                    }
                    else {
                        visibility = 1;
                    }

                    if (this.itemNote == null) {

                        this.listHistory.push({
                            id: 0,
                            idWorkOrderReference: parseFloat(Math.random().toFixed(5)),
                            dateRegisterFormatted: moment().format("MM/DD/YYYY HH:MM"),
                            userName: this.userLoggedGetters.name,
                            description: this.note.trim(),
                            visibility: visibility,
                            visibilityDescription: visibilityDescription,
                            workOrderPhotos: this.workOrderPhotos,
                            qtdPictures: this.workOrderPhotos.length,
                            newRegister: true
                        })
                    }
                    else {
                        this.itemNote.description = this.note.trim();
                        this.itemNote.visibility = visibility;
                        this.itemNote.visibilityDescription = visibilityDescription;
                        this.itemNote.workOrderPhotos = this.workOrderPhotos;
                        this.itemNote.qtdPictures = this.workOrderPhotos.length;
                    }

                    this.$forceUpdate();

                    this.showDialogNote = false;
                }
            },

            confirmNoteDelete(id) {

                let index = 0
                this.listHistory.forEach(itemHistory => {
                    if (itemHistory.idWorkOrderReference == id) {

                        this.listHistory.splice(index, 1);
                    }
                    index++;
                });
            },

            async uploadPhotos(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.WORK_ORDER.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async savePhotos() {

                let allResult = []

                this.workOrderDetailPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadPhotos(itemPhoto.id, this.workOrderRequest.id, itemPhoto.file, TYPE_DOCUMENT.WORK_ORDER_DETAIL.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.WORK_ORDER_DETAIL.description,
                            message: result.message
                        })
                    }
                })

                this.listHistory.forEach(async itemHistory => {
                    itemHistory.workOrderPhotos.forEach(async itemPhoto => {

                        let result = await this.uploadPhotos(itemPhoto.id, itemHistory.id, itemPhoto.file, TYPE_DOCUMENT.WORK_ORDER_HISTORY.value, itemPhoto.deleted);

                        if (result.success == false) {
                            allResult.push({
                                typeDocument: TYPE_DOCUMENT.WORK_ORDER_HISTORY.description,
                                message: result.message
                            })
                        }
                    })
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload pictures: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    this.workOrderRequest.id = this.id;
                    this.workOrderRequest.idInstaller = 0;
                    this.workOrderRequest.idFieldSuper = 0;
                    this.workOrderRequest.typeWorkOrder = 0;

                    if (this.workOrderRequest.projectSelected != null && this.workOrderRequest.projectSelected != null) {
                        this.workOrderRequest.idServiceProject = this.workOrderRequest.projectSelected.id
                    }

                    if (this.workOrderRequest.stageSelected != null && this.workOrderRequest.stageSelected != null) {
                        this.workOrderRequest.idStage = this.workOrderRequest.stageSelected.id
                    }

                    if (this.workOrderRequest.workOrderTypeSelected != null && this.workOrderRequest.workOrderTypeSelected != null) {
                        this.workOrderRequest.typeWorkOrder = this.workOrderRequest.workOrderTypeSelected.id
                    }

                    if (this.workOrderRequest.installerSelected != null && this.workOrderRequest.installerSelected != null) {
                        this.workOrderRequest.idInstaller = this.workOrderRequest.installerSelected.id
                    }

                    if (this.workOrderRequest.fieldSuperSelected != null && this.workOrderRequest.fieldSuperSelected != null) {
                        this.workOrderRequest.idFieldSuper = this.workOrderRequest.fieldSuperSelected.id
                    }

                    if (this.workOrderRequest.listProductCategorySelected != null && this.workOrderRequest.listProductCategorySelected != undefined) {
                        this.workOrderRequest.idListProductCategory = '';

                        this.workOrderRequest.listProductCategorySelected.forEach(item => {
                            this.workOrderRequest.idListProductCategory += `${item.id};`
                        });
                    }

                    if (this.workOrderRequest.listProductSelected != null && this.workOrderRequest.listProductSelected != undefined) {
                        this.workOrderRequest.idListProduct = '';

                        this.workOrderRequest.listProductSelected.forEach(item => {
                            this.workOrderRequest.idListProduct += `${item.id};`
                        });
                    }

                    this.workOrderRequest.targetDate = this.targetDate;
                    this.workOrderRequest.warrantyExpirationDate = this.warrantyExpirationDate;
                    this.workOrderRequest.listHistory = [...this.listHistory];

                    const result = await this.$store.dispatch("workOrderModule/CreateUpdate", this.workOrderRequest);
                    
                    if (result.success === true) {
                        this.workOrderRequest = result.workOrderResponse;

                        this.workOrderRequest.listHistory.forEach(itemHistoryResponse => {
                            this.listHistory.forEach(itemHistoryLocal => {
                                if (itemHistoryLocal.idWorkOrderReference == itemHistoryResponse.idWorkOrderReference) {
                                    itemHistoryLocal.id = itemHistoryResponse.id;
                                }
                            });
                        });

                        if (await this.savePhotos()) {

                            if (result.success === true) {
                                this.showToast("success", "Success!", result.message);
                                this.cancel();
                            }
                            else {
                                this.showToast("error", "Warning!", result.message);
                            }
                        }
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>


<style scoped>

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>
